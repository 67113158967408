<template>
  <div class="container">
    <Swiper v-if="info.coverList.length > 0">
       <Slide v-for="(item,index) in info.coverList" :key="index">
        <img :src="item" class="bg-img" />
       </Slide>
  </Swiper>
    <!-- <img :src="info.cover" class="bg-img" /> -->
    <div class="content pt-60 prow-30">
      <div class="title">{{ info.name }}</div>

      <div class="phone f-fs-c mt-30">
        <van-icon class="pr-10" size=".28rem" name="phone-o" />
        {{ info.phonenumber }}
      </div>
      <div class="address f-fs-c mt-8">
        <van-icon class="pr-10" size=".28rem" name="location-o" />
        {{ info.address }}
      </div>

      <Collapse :msg="info.brief" title="场景介绍" />

      <div class="photo-title f-sb-c mt-60">
        <div class="f-fs-c">
          <img src="~@/assets/label.png" class="lable mr-20" />
          成长记
        </div>
        <div class="photo-tip f-fs-c">
          {{ info.picCount || 0 }}张
          <van-icon name="arrow" color="#878794" size=".24rem" />
        </div>
      </div>
      <div class="photo-list mt-30">
        <img v-for="(item, index) in info.photoWarehouses" :key="index" class="item-img pr-10" :src="item.listPreview" />
      </div>

      <div class="photo-title f-sb-c mt-60" v-if="info.activity">
        <div class="f-fs-c">
          <img src="~@/assets/label.png" class="lable mr-20" />
          欢乐活动
        </div>
      </div>
      <div class="activity-item mt-20" @click="handlerToActivity(info.activity.id)" v-if="info.activity">
        <img :src="info.activity && info.activity.cover" class="activity-img" />
        <p class="activity-title mt-20 mrow-20">{{ info.activity && info.activity.name }}</p>
        <p class="activity-brief mt-20 mrow-20">{{ info.activity && info.activity.brief | str60 }}</p>
        <div class="timer f-fs-c mt-30 mrow-20 pb-20">
          <van-icon class="pr-10" size=".28rem" name="clock-o" />
          {{ info.activity && info.activity.eventStartDate }}
        </div>
      </div>

      <div class="blank"></div>
    </div>
    <download-app :hidden="$route.query.hidden" />
  </div>
</template>

<script>
import { getItem } from '@/api/kindergarten';
import Collapse from '@/components/Collapse.vue';
import DownloadApp from '@/components/DownloadApp.vue';
import { Swiper, Slide } from 'vue-swiper-component';

export default {
  name: 'ActivityIndex',

  filters: {
    str60: function (str) {
      return str && str.slice(0, 60);
    },
    showTimer: function (time) {
      return time && time.slice(0, 10);
    },
  },

  components: {
    Collapse,
    DownloadApp,
    Swiper,
    Slide
  },

  data: function () {
    return {
      id: '',
      info: {
        cover: '',
        name: '',
        phonenumber: '',
        address: '',
        brief: null,
        photoWarehouses: [],
        picCount: 0,
        activity: {
          cover: '',
          name: '',
          brief: '',
          eventStartDate: '',
        },
      },
    };
  },

  computed: {},

  watch: {
    '$route.query': {
      handler(obj) {
        if (obj.id) {
          this.id = obj.id;
        }
      },
      immediate: true,
      deep: true,
    },
    id: {
      handler(str) {
        if (str) {
          this.loadData();
        }
      },
      immediate: true,
    },
  },

  methods: {
    handlerToActivity(id) {
      this.$router.push({
        path: `/h5/activity?id=${id}`,
      });
    },
    async loadData() {
      const { data } = await getItem({
        id: this.id,
      });

      this.info = data;
    },
  },
};
</script>

<style lang="scss" scoped>
.container {
  width: 100vw;
  background-color: #fff;
  position: relative;
  .bg-img {
    width: 100vw;
  }
  .content {
    box-sizing: border-box;
    position: relative;
    top: -0.1rem;
    z-index: 1;
    width: 100vw;
    min-height: 0.4rem;
    background-color: #fff;
    border-radius: 0.3rem 0.3rem 0 0;
    .title {
      color: #0a1738;
      font-size: 0.4rem;
      font-weight: bold;
    }
    .phone,
    .address {
      font-size: 0.24rem;
      color: #0a1738;
    }
  }

  .photo-title {
    color: #0a1738;
    font-size: 0.3rem;
    font-weight: bold;
    .lable {
      width: 0.05rem;
      height: 0.26rem;
    }
    .photo-tip {
      font-size: 0.24rem;
      font-weight: normal;
      color: #878794;
    }
  }
  .photo-list {
    text-align: center;
    white-space: nowrap;
    overflow-x: scroll;
    overflow-y: hidden;
    width: 100%;
    .item-img {
      height: 2rem;
      border-radius: 0.2rem;
    }
  }

  .activity-item {
    width: 100%;
    border-radius: 0.28rem;
    background-color: #fff;
    overflow: hidden;
    box-shadow: 0 0 0.2rem 0 #e8edfb;

    .activity-img {
      width: 100%;
    }
    .activity-title {
      color: #0a1738;
      font-size: 0.3rem;
      font-weight: bold;
    }
    .activity-brief,
    .timer {
      color: #878794;
      font-size: 0.24rem;
    }
  }
  .blank {
    width: 100%;
    min-height: 0.8rem;
  }
}
</style>
