<template>
  <div class="txt mt-30">
    <div v-if="title" class="title pb-10">{{ title }}</div>
    <template v-if="needCut">
      <div>
        {{ showAll ? msg : preTxt }}
        <div class="f-fe-c btn mt-8" @click="changeShow">
          {{ showAll | showTxt }}
          <van-icon
            :class="[showAll ? 'open' : 'close']"
            color="#87C6FF"
            name="arrow"
          />
        </div>
      </div>
    </template>
    <template v-else>{{ msg }}</template>
  </div>
</template>

<script>
export default {
  name: 'Collapse',
  filters: {
    showTxt: function (value) {
      return value ? '收起' : '展开';
    }
  },
  props: {
    msg: String,
    title: String
  },

  data() {
    return {
      showAll: false
    };
  },

  computed: {
    needCut() {
      if (this.msg === null) {return false;}
      if (this.msg.length > 60) {
        return true;
      }
      return false;
    },
    preTxt() {
      if (this.msg.length > 60) {
        return this.msg.slice(0, 60);
      } else {
        return this.msg;
      }
    }
  },

  methods: {
    changeShow() {
      this.showAll = !this.showAll;
    }
  }
};
</script>

<style scoped lang="scss">
.mt-8 {
  margin-top: 0.08rem;
}
.txt {
  color: #878794;
  font-size: 0.24rem;
  word-break: break-all;
  .title {
    color: #0a1738;
    font-size: 0.3rem;
    font-weight: bold;
  }
  .btn {
    color: #87c6ff;
  }
  .open {
    transform: rotate(-90deg) translateZ(0);
    transition: transform 0.3s;
  }
  .close {
    transform: rotate(90deg) translateZ(0);
    transition: transform 0.3s;
  }
}
</style>
