<template>
  <div v-show="showTip && hidden !== '1'" class="download-app f-sb-c">
    <div class="f-fs-c">
      <img src="~@/assets/logo-mini.png" class="logo" />
      <div class="title">幼享乐App</div>
    </div>
    <div class="f-fs-c">
      <van-button class="confirm-btn" block type="info" url="https://a.app.qq.com/o/simple.jsp?pkgname=com.happy.kindergarten">立即下载</van-button>
      <van-icon name="close" color="#FFF" class="colse-icon" size=".4rem" @click="changeShow" />
    </div>
  </div>
</template>

<script>
export default {
  name: 'DownloadApp',
  filters: {},

  props: {
    hidden: {
      type: String,
      default: '0',
    },
  },

  data() {
    return {
      showTip: true,
    };
  },

  methods: {
    changeShow() {
      this.showTip = false;
    },
  },
};
</script>

<style scoped lang="scss">
.download-app {
  box-sizing: border-box;
  position: fixed;
  z-index: 1;
  bottom: 0;
  left: 0;
  width: 100vw;
  height: 1.1rem;
  background-color: rgba(0, 0, 0, 0.6);
  backdrop-filter: blur(4px);
  padding: 0 0.2rem 0.1rem 0.2rem;
  .logo {
    width: 0.8rem;
    height: 0.8rem;
    border-radius: 0.1rem;
  }
  .title {
    margin-left: 0.12rem;
    color: #fff;
    font-size: 0.3rem;
    font-weight: bold;
  }
  .confirm-btn {
    border-radius: 0.08rem;
    line-height: 0.6rem;
    height: 0.6rem;
    font-size: 0.26rem;
  }
  .colse-icon {
    margin-left: 0.4rem;
  }
}
</style>
